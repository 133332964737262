@font-face {
  font-family: 'Karla';
  src: url("./resources/fonts/Karla/Karla-Regular.ttf") format("truetype")
}

@font-face {
  font-family: 'Karla-Bold';
  src: url("./resources/fonts/Karla/Karla-Bold.ttf") format("truetype")
}

@font-face {
  font-family: 'Karla-Italic';
  src: url("./resources/fonts/Karla/Karla-Italic.ttf") format("truetype")
}

@font-face {
  font-family: 'Karla-Bolditalic';
  src: url("./resources/fonts/Karla/Karla-BoldItalic.ttf") format("truetype")
}

@font-face {
  font-family: 'Tiempos';
  src: url("./resources/fonts/Times/TiemposText-Bold.ttf") format("truetype")
}

* {
  box-sizing: content-box;
}

body {
  color: #1e1e1e;
  font-family: 'Karla';
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  width: 100%;
}

p {
  line-height: 32px;
  margin-top: 0;
}

a {
  color: #3aa8ff;
  text-decoration: none;
  &:hover {
    color: #136eb6;
  }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
}

header {
  align-items: center;
  display: flex;
  height: 65px;
  div, nav {
    display: flex;
    align-items: center;
    .nav-item {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  a {
    color: inherit;
    &:hover {
      color: #3aa8ff;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  width: 90%;
  &.header-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.masonry {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 1100px;
  padding: 60px 0px;
}

.work-card {
  margin: 20px;
  width: calc((100% / 2) - 40px);
  .work-header {
    border-radius: 16px;
    height: 250px;
    &:hover {
      box-shadow: 0 0 12px 0 lightGray;
      transform: scale(1.1);
    }
    img {
      border-radius: 16px;
      height: 250px;
      object-fit: cover;
      width: 100%;
    }
  }
  .work-body {
    text-align: center;
    h3 {
      color: rgba(30,30,30,0.8);
      font-family: 'Karla-Bold';
      font-size: 18px;
      margin-bottom: 0;
    }
    p {
      color: rgba(30,30,30,0.24);
      font-family: 'Karla-Bolditalic';
      font-size: 16px;
      margin: 0;
    }
  }
}

.logo {
  font-family: 'Tiempos';
  font-size: 24px;
  font-weight: 700;
}

.main-title {
  font-family: 'Tiempos';
  font-size: 72px;
  line-height: 76px;
  margin: 0;
  margin-bottom: 15px;
}

.title {
  font-family: 'Tiempos';
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.hero {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 70vh;
  justify-content: space-between;
  .bio-container {
    flex: 1 0 60%;
    .greeting {
      color: silver;
      font-size: 28px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .bio {
      color: silver;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
    }
  }
  .bio-image {
    flex: 1 0 40%;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.hero-page {
  height: 75vh;
  img {
    height: 75vh;
    object-fit: cover;
    width: 100%; 
  }
}

.description-page {
  display: flex;
  flex-direction: row;
  padding: 40px 0px;
  .page-column-one {
    flex: 0 1 30%;
    padding-right: 20px;
    .title {
      margin: 0
    }
  }
  .page-column-two {
    flex: 0 1 70%;
    padding-left: 20px;
  }
}

#bio {
  padding: 80px 0px;
  .get-cv {
    margin-top: 60px;
    text-align: center;
    button {
      align-items: center;
      background-color: #fff;
      border: 1px solid #3aa8ff;
      border-radius: .375em;
      box-shadow: none;
      color: #3aa8ff;
      cursor: pointer;
      display: inline-flex;
      font-size: 1rem;
      justify-content: center;
      height: 2.5em;
      line-height: 1.5;
      padding-bottom: calc(.5em - 1px);
      padding-left: 1em;
      padding-right: 1em;
      padding-top: calc(.5em - 1px);
      position: relative;
      text-align: center;
      vertical-align: top;
      white-space: nowrap;
    }
  }
}

footer {
  padding-bottom: 60px;
  font-size: 18px;
  h3 {
    margin: 15px 0;
  }
  a {
    font-size: 18px;
  }
}

.footer-links-container {
  display: flex;
  .footer-link {
    align-items: center;
    display: flex;
    border-radius: 50%;
    background-color: silver;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-right: 8px;
    .link-icon {
      color: #fff;
      font-size: 18px;
    }
  }
}

@media (max-width: 700px) {
  .hero {
    flex-direction: column;
    height: 100vh;
    .bio-image{
      flex: 1 0 100vw;
      height: 65%;
    }
    .bio-container {
      flex: 1 0 100vw;
      height: 35%;
      order: 2;
    }
  }

  .masonry {
    display: block;
    height: auto;
  }

  .work-card {
    margin: 30px 0;
    width: 100%;
  }

  .description-page {
    display: block;
    .page-column-one {
      margin-bottom: 15px;
    }
    .page-column-two {
      padding: 0;
    }
  }


  footer {
    .column-container {
      display: block;
    }
  }
}


